import moment from 'moment';
import dateFormat from '@/helpers/DateFormat.helper';
import { chartHelper } from '@/helpers/Chart.helper';
import { Serie } from '@/models/Statistics';

const zoomHours = 8;

export function getChartData(
  glucoseSerie: Serie | undefined,
  heartRateSerie: Serie | undefined,
  showGlucose: boolean,
  showHr: boolean,
): any {
  const datasets = [];

  function getData(serie: Serie): { x: number; y: number }[] {
    const data = [];
    for (let i = 0; i < serie.xaxis.length; i++) {
      const label = moment(dateFormat.utcToUserTimezone(serie.xaxis[i])).valueOf();
      data.push({
        x: label,
        y: Math.trunc(serie.data[i]),
      });
    }
    return data;
  }

  if (showGlucose && glucoseSerie?.data && glucoseSerie.data.length > 0) {
    datasets.push({
      label: '',
      data: getData(glucoseSerie),
      borderColor: '#8459C0',
      showLine: true,
      tension: 0.4,
      backgroundColor: chartHelper.calculateGradient('chartId', 240),
      fill: true,
      spanGaps: 1000 * 60 * 30,
      tooltip: {
        enabled: true,
        titleColor: '#233D4D',
        backgroundColor: '#ffffff',
        bodyColor: '#2E194B',
        bodyFont: {
          familiy: 'Rubik',
          weight: 'bold',
          size: 14,
        },
        titleFont: 'Rubik',
        cornerRadius: 4,
        padding: 8,
        usePointStyle: 'circle',
        callbacks: {
          label: function (text: any) {
            return text.parsed.y + 'mg/dL';
          },
          title: function (tooltipItems: any) {
            return dateFormat.formatDateWithHour(tooltipItems[0].parsed.x);
          },
        },
      },
    });
  }

  if (heartRateSerie && showHr) {
    const groupedData = groupDataByDayHourAndMinute(heartRateSerie);

    if (groupedData) {
      datasets.push({
        label: '',
        data: getData(groupedData),
        borderColor: '#6D00FF',
        showLine: true,
        tension: 0.4,
        fill: false,
        spanGaps: 1000 * 60 * 30,
        tooltip: {
          enabled: true,
          titleColor: '#233D4D',
          backgroundColor: '#ffffff',
          bodyColor: '#2E194B',
          bodyFont: {
            familiy: 'Rubik',
            weight: 'bold',
            size: 14,
          },
          titleFont: 'Rubik',
          cornerRadius: 4,
          padding: 8,
          usePointStyle: 'circle',
          callbacks: {
            label: function (text: any) {
              return text.parsed.y + 'ppm';
            },
            title: function (tooltipItems: any) {
              return dateFormat.formatDateWithHour(tooltipItems[0].parsed.x);
            },
          },
        },
      });
    }
  }

  if (glucoseSerie && glucoseSerie.xaxis?.length > 0) {
    return {
      datasets,
    };
  }

  if (heartRateSerie && heartRateSerie.xaxis?.length > 0) {
    return {
      datasets,
    };
  }

  return {
    labels: [],
    datasets,
  };
}

export function getChartOptions(startDateChart: string, endDateChart: string, annotation: any, zoom = false): any {
  const panMinLimit = new Date(dateFormat.utcToUserTimezone(startDateChart)).getTime();
  const panMaxLimit = new Date(dateFormat.utcToUserTimezone(endDateChart)).getTime();

  const chartOptions: any = {
    animation: {
      duration: 0,
    },
    elements: {
      point: {
        radius: 0,
      },
      line: {
        borderWidth: 2,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      annotation: annotation,
      zoom: {
        pan: {
          enabled: zoom,
          mode: 'x',
        },
        limits: {
          x: { min: panMinLimit, max: panMaxLimit },
        },
        zoom: {
          mode: 'x',
          wheel: {
            enabled: false,
          },
          pinch: {
            enabled: false,
          },
        },
      },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        suggestedMin: new Date(dateFormat.utcToUserTimezone(startDateChart)).getTime(),
        suggestedMax: new Date(dateFormat.utcToUserTimezone(endDateChart)).getTime(),
        title: {
          display: true,
        },
        type: 'time',
        time: {
          unit: 'hour',
        },
        grid: {
          display: 'false',
          drawOnChartArea: false,
          drawBorder: true,
          drawTicks: false,
        },
        ticks: {
          color: '#97A6B5',
          padding: 8,
          font: {
            size: 11,
            family: 'Rubik',
          },
        },
      },
      y: {
        suggestedMin: 40,
        suggestedMax: 210,
        grid: {
          display: 'true',
          drawOnChartArea: true,
          drawBorder: true,
          drawTicks: false,
        },
        ticks: {
          color: '#97A6B5',
          padding: 8,
          font: {
            size: 11,
            family: 'Rubik',
          },
        },
      },
    },
  };
  if (zoom) {
    const panMaxDate = moment().isBetween(startDateChart, endDateChart) ? new Date() : new Date(endDateChart);
    const initialPanMax = panMaxDate.getTime();
    const panMinDate = panMaxDate;
    const hoursToSubstract = panMaxDate.getHours() >= zoomHours ? zoomHours : panMaxDate.getHours();
    panMinDate.setHours(panMaxDate.getHours() - hoursToSubstract);
    const initialPanMin = panMinDate.getTime();
    chartOptions.scales.x.min = initialPanMin;
    chartOptions.scales.x.max = initialPanMax;
  } else {
    chartOptions.scales.x.min = new Date(dateFormat.utcToUserTimezone(startDateChart)).getTime();
    chartOptions.scales.x.max = new Date(dateFormat.utcToUserTimezone(endDateChart)).getTime();
  }
  return chartOptions;
}

export function getAxisFormatted(axis: string[]): any[] {
  return axis.map((date: any) => {
    return moment(dateFormat.utcToUserTimezone(date)).valueOf();
  });
}

/**
 * Groups the initial chart data by day, hour, and minute. Calculates the average value for each group and replaces the original data with the grouped data.
 * @param initialChartData - The initial chart data to be grouped.
 * @returns The modified initialChartData object with the data grouped by day, hour, and minute.
 */
export function groupDataByDayHourAndMinute(initialChartData: any): Serie | undefined {
  if (!initialChartData) {
    return undefined;
  }
  const groupedData = new Map<string, { values: number[]; count: number }>();
  for (let i = 0; i < initialChartData.data.length; i++) {
    const date = moment(initialChartData.xaxis[i]).seconds(0).milliseconds(0);
    const dayHourMinute = date.format('YYYY-MM-DDTHH:mm:ss');
    if (groupedData.has(dayHourMinute)) {
      const group = groupedData.get(dayHourMinute);
      if (group) {
        group.values.push(initialChartData.data[i]);
        group.count++;
      }
    } else {
      groupedData.set(dayHourMinute, { values: [initialChartData.data[i]], count: 1 });
    }
  }
  return calculateAverages(groupedData, initialChartData);
}
function calculateAverages(groupedData: Map<string, { values: number[]; count: number }>, initialChartData: any) {
  const newData = { ...initialChartData };
  const averages: { minute: string; average: number }[] = [];
  groupedData.forEach((data, dayHourMinute) => {
    const average = data.values.reduce((total, value) => total + value, 0) / data.count;
    averages.push({ minute: dayHourMinute, average });
  });
  newData.data = averages.map((item) => item.average);
  newData.xaxis = averages.map((item) => item.minute);
  return newData;
}
