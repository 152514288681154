import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "chart" }
const _hoisted_2 = { class: "c-chart-filters" }
const _hoisted_3 = { class: "c-chart-filters__item" }
const _hoisted_4 = { for: "switchGlucose" }
const _hoisted_5 = { class: "c-chart-filters__item" }
const _hoisted_6 = { for: "switchHeartRate" }
const _hoisted_7 = { class: "c-chart-y-label" }
const _hoisted_8 = { class: "c-chart__container" }
const _hoisted_9 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSwitch = _resolveComponent("InputSwitch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_InputSwitch, {
          modelValue: _ctx.showGlucose,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showGlucose) = $event)),
          inputId: "switchGlucose",
          disabled: _ctx.noDataGlusoce
        }, null, 8, ["modelValue", "disabled"]),
        _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('wearables.sleepChart.glucoseCheck')), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_InputSwitch, {
          modelValue: _ctx.showHr,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showHr) = $event)),
          inputId: "switchHeartRate",
          disabled: _ctx.noDataHr
        }, null, 8, ["modelValue", "disabled"]),
        _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('wearables.sleepChart.heartRateCheck')), 1)
      ])
    ]),
    _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('wearables.sleepChart.y-axe-label')), 1),
    _createElementVNode("div", _hoisted_8, [
      (_ctx.chartId)
        ? (_openBlock(), _createElementBlock("canvas", {
            key: 0,
            id: _ctx.chartId
          }, null, 8, _hoisted_9))
        : _createCommentVNode("", true)
    ])
  ]))
}