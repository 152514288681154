
import { chartHelper } from '@/helpers/Chart.helper';
import { getChartData, getChartOptions } from '@/helpers/Chart.wearables.helper';
import { EventBase } from '@/models/Event';
import { Serie } from '@/models/Statistics';
import Chart, { ChartConfiguration } from 'chart.js/auto';
import 'chartjs-adapter-moment';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import { computed, defineComponent, nextTick, onUnmounted, PropType, ref, watchEffect } from 'vue';

export default defineComponent({
  components: {},
  emits: ['annotation-event-click'],
  props: {
    chartId: {
      type: String,
      required: false,
      default: () => {
        return 'wearable-glucose-chart';
      },
    },
    glucoseSerie: {
      type: Object as PropType<Serie>,
      required: true,
    },
    heartRateSerie: {
      type: Object as PropType<Serie>,
      required: true,
    },
    chartStart: {
      type: String,
      required: true,
    },
    chartEnd: {
      type: String,
      required: true,
    },
    zoom: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    events: {
      type: Object as PropType<EventBase[]>,
    },
    clickableAnnotations: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },

  setup(props, context) {
    Chart.register(annotationPlugin);
    Chart.register(zoomPlugin);

    let chart: Chart | null = null;

    const chartData = computed(() => {
      return getChartData(props.glucoseSerie, props.heartRateSerie, showGlucose.value, showHr.value);
    });

    const chartOptions = computed(() => {
      return getChartOptions(props.chartStart, props.chartEnd, annotation.value, props.zoom);
    });

    const annotation = computed(() => {
      return props.events && props.events.length > 0
        ? chartHelper.getEventsAnnotationWearables({
            context,
            maxValue: 50,
            clickable: true,
            events: props.events,
            timezone: '',
          })
        : null;
    });

    const noDataGlusoce = computed(() => {
      return props.glucoseSerie?.data && props.glucoseSerie?.data.length == 0;
    });

    const noDataHr = computed(() => {
      return props.heartRateSerie?.data && props.heartRateSerie?.data.length == 0;
    });

    const showHr = ref(!noDataHr.value);
    const showGlucose = ref(!noDataGlusoce.value);

    watchEffect(() => {
      handleDataChange(chartData.value, chartOptions.value);
    });

    onUnmounted(() => {
      if (chart) {
        chart.destroy();
      }
    });

    async function handleDataChange(chartData: any, chartOptions: any) {
      if (chart) {
        chart?.destroy();
      }

      if (!chartData && !chartOptions) {
        return;
      }

      const container = document.getElementById(props.chartId);
      if (!container) {
        await nextTick(() => {
          drawChart();
        });
      } else {
        drawChart();
      }
    }

    function drawChart() {
      const container = document.getElementById(props.chartId);
      if (!container) {
        return;
      }
      const canvas = container as HTMLCanvasElement;
      if (!canvas) {
        return;
      }
      const chartConfig: ChartConfiguration = {
        type: 'line',
        data: chartData.value,
        options: chartOptions.value,
      };

      const ctx = canvas.getContext('2d');
      if (ctx && chartConfig.data.datasets.length > 0) {
        chartConfig.data.datasets[0].backgroundColor = chartHelper.calculateGradient(props.chartId, 240);
      }

      chart = new Chart(canvas, chartConfig);
    }

    return {
      noDataGlusoce,
      noDataHr,
      showGlucose,
      showHr,
    };
  },
});
